// Generated by Framer (4025f14)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["xnKaQUsz1", "nyteL1KCK", "ZdqO5nBrt"];

const variantClassNames = {nyteL1KCK: "framer-v-xbqqlp", xnKaQUsz1: "framer-v-1gs1u3t", ZdqO5nBrt: "framer-v-ilfc76"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "xnKaQUsz1", "Tab 2": "nyteL1KCK", "Tab 3": "ZdqO5nBrt"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "xnKaQUsz1", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xnKaQUsz1", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap1yn9ds4 = activeVariantCallback(async (...args) => {
setVariant("xnKaQUsz1")
})

const tap1doalrh = activeVariantCallback(async (...args) => {
setVariant("nyteL1KCK")
})

const tapa15jlq = activeVariantCallback(async (...args) => {
setVariant("ZdqO5nBrt")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5uXtz", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1gs1u3t", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"xnKaQUsz1"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({nyteL1KCK: {"data-framer-name": "Tab 2"}, ZdqO5nBrt: {"data-framer-name": "Tab 3"}}, baseVariant, gestureVariant)}><motion.div className={"framer-wx1ptp-container"} layoutDependency={layoutDependency} layoutId={"MHGZI_2ve-container"} transition={transition}><Tab height={"100%"} id={"MHGZI_2ve"} layoutId={"MHGZI_2ve"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({nyteL1KCK: {tap: tap1yn9ds4, variant: "ym0P2kopY"}, ZdqO5nBrt: {tap: tap1yn9ds4, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1crneqy-container"} layoutDependency={layoutDependency} layoutId={"La0TZDZcf-container"} transition={transition}><Tab height={"100%"} id={"La0TZDZcf"} layoutId={"La0TZDZcf"} tap={tap1doalrh} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({nyteL1KCK: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-13th7zb-container"} layoutDependency={layoutDependency} layoutId={"shKzvYLop-container"} transition={transition}><Tab height={"100%"} id={"shKzvYLop"} layoutId={"shKzvYLop"} tap={tapa15jlq} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({ZdqO5nBrt: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5uXtz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5uXtz * { box-sizing: border-box; }", ".framer-5uXtz .framer-dkdqt6 { display: block; }", ".framer-5uXtz .framer-1gs1u3t { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-5uXtz .framer-wx1ptp-container, .framer-5uXtz .framer-1crneqy-container, .framer-5uXtz .framer-13th7zb-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5uXtz .framer-1gs1u3t { gap: 0px; } .framer-5uXtz .framer-1gs1u3t > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-5uXtz .framer-1gs1u3t > :first-child { margin-left: 0px; } .framer-5uXtz .framer-1gs1u3t > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"nyteL1KCK":{"layout":["auto","auto"]},"ZdqO5nBrt":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const Framerh7HS4qY3C: React.ComponentType<Props> = withCSS(Component, css, "framer-5uXtz") as typeof Component;
export default Framerh7HS4qY3C;

Framerh7HS4qY3C.displayName = "Elements/Tabs";

Framerh7HS4qY3C.defaultProps = {height: 49, width: 275};

addPropertyControls(Framerh7HS4qY3C, {variant: {options: ["xnKaQUsz1", "nyteL1KCK", "ZdqO5nBrt"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(Framerh7HS4qY3C, [...TabFonts])